<template>
  <div class="row">
    <div class="col-12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <Confirm ref="confirm"></Confirm>
      <div class="card">
        <div class="card-body">
          <CForm>
            <template v-slot="header">
              Edita Usuario id: {{ $route.params.id }}
            </template>
            <CAlert
                :show.sync="dismissCountDown"
                color="primary"
                fade
            >
              ({{ dismissCountDown }}) {{ message }}
            </CAlert>
            <div class="row">
              <div class="col">
                <CInput type="text" label="Nombre" placeholder="Name" v-model="name"></CInput>
              </div>
              <div class="col">
                <CInput type="text" label="Correo Electrónico" placeholder="Email" v-model="email"></CInput>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table table-sm table-bordered table-hover">
                    <tr>
                      <th class="text-center align-middle" rowspan="3" colspan="2" style="width: 280px">MENUS</th>
                      <th class="text-center align-middle text-white bg-success" colspan="7">SEDE LA PAZ</th>
                      <th class="text-center align-middle text-white bg-info" colspan="4">SEDE EL ALTO</th>
                      <th class="text-center align-middle text-white bg-warning" colspan="2">U.U.B.</th>
                    </tr>
                    <tr>
                      <th class="text-center align-middle text-white bg-success">CAJAS</th>
                      <th class="text-center align-middle text-white bg-success">REGISTROS</th>
                      <th class="text-center align-middle text-white bg-success">DIRECCIÓN<br/>DE BIENESTAR</th>
                      <th class="text-center align-middle text-white bg-success">JEFES DE CARRERA</th>
                      <th class="text-center align-middle text-white bg-success">SISTEMAS</th>
                      <th class="text-center align-middle text-white bg-success">DAF</th>
                      <th class="text-center align-middle text-white bg-success">POST-GRADO</th>
                      <th class="text-center align-middle text-white bg-info">CAJAS EL ALTO</th>
                      <th class="text-center align-middle text-white bg-info">DIRECCIÓN<br/>DE BIENESTAR</th>
                      <th class="text-center align-middle text-white bg-info">JEFES DE CARRERA</th>
                      <th class="text-center align-middle text-white bg-info">VICERRECTOR<BR/>EL ALTO</th>
                      <th class="text-center align-middle text-white bg-warning">DIREC-TORIO</th>
                      <th class="text-center align-middle text-white bg-warning">ADMINIS-TRADOR</th>
                    </tr>
                    <tr>
                      <th class="text-center align-middle bg-success"><input type="radio" name="perfil" class="form-control" :checked="(perfilUsuario=='rolesmenu_cajas')" @click="habilitaColumna('rolesmenu_cajas','LA PAZ - CAJAS')"/></th>
                      <th class="text-center align-middle bg-success"><input type="radio" name="perfil" class="form-control" :checked="(perfilUsuario=='rolesmenu_registros')" @click="habilitaColumna('rolesmenu_registros','LA PAZ - REGISTROS')"/></th>
                      <th class="text-center align-middle bg-success"><input type="radio" name="perfil" class="form-control" :checked="(perfilUsuario=='rolesmenu_bienestar')" @click="habilitaColumna('rolesmenu_bienestar','LA PAZ - DIRECCIÓN DE BIENESTAR')"/></th>
                      <th class="text-center align-middle bg-success"><input type="radio" name="perfil" class="form-control" :checked="(perfilUsuario=='rolesmenu_jefe')" @click="habilitaColumna('rolesmenu_jefe','LA PAZ - JEFES DE CARRERA')"/></th>
                      <th class="text-center align-middle bg-success"><input type="radio" name="perfil" class="form-control" :checked="(perfilUsuario=='rolesmenu_sistemas')" @click="habilitaColumna('rolesmenu_sistemas','LA PAZ - SISTEMAS')"/></th>
                      <th class="text-center align-middle bg-success"><input type="radio" name="perfil" class="form-control text-primary" :checked="(perfilUsuario=='rolesmenu_daf')" @click="habilitaColumna('rolesmenu_daf','LA PAZ - DAF')"/></th>
                      <th class="text-center align-middle bg-success"><input type="radio" name="perfil" class="form-control" :checked="(perfilUsuario=='rolesmenu_postgrado')" @click="habilitaColumna('rolesmenu_postgrado','LA PAZ - POSTGRADO')"/></th>
                      <th class="text-center align-middle bg-info"><input type="radio" name="perfil" class="form-control" :checked="(perfilUsuario=='rolesmenu_ea_cajas')"  @click="habilitaColumna('rolesmenu_ea_cajas','EL ALTO - CAJAS EL ALTO')"/></th>
                      <th class="text-center align-middle bg-info"><input type="radio" name="perfil" class="form-control" :checked="(perfilUsuario=='rolesmenu_ea_bienestar')"  @click="habilitaColumna('rolesmenu_ea_bienestar','EL ALTO - DIRECCIÓN DE BIENESTAR')"/></th>
                      <th class="text-center align-middle bg-info"><input type="radio" name="perfil" class="form-control" :checked="(perfilUsuario=='rolesmenu_ea_jefe')"  @click="habilitaColumna('rolesmenu_ea_jefe','EL ALTO - JEFES DE CARRERA')"/></th>
                      <th class="text-center align-middle bg-info"><input type="radio" name="perfil" class="form-control" :checked="(perfilUsuario=='rolesmenu_ea_vicerrector')" @click="habilitaColumna('rolesmenu_ea_vicerrector','EL ALTO - VICERRECTOR EL ALTO')"/></th>
                      <th class="text-center align-middle bg-warning"><input type="radio" name="perfil" class="form-control" :checked="(perfilUsuario=='rolesmenu_directorio')"  @click="habilitaColumna('rolesmenu_directorio','U.U.B. - DIRECTORIO')"/>
                        <input v-model="rolesmenu_directorio" :value="'admin-ub'"
                               :checked="true"
                               class="d-nones rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/> Admin-ub<br/>
                        <input v-model="rolesmenu_directorio" :value="'admin'"
                               :checked="true"
                               class="d-nones rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/> Admin
                      </th>
                      <th class="text-center align-middle bg-warning"><input type="radio" name="perfil" class="form-control" :checked="(perfilUsuario=='rolesmenu_administrador')"  @click="habilitaColumna('rolesmenu_administrador','U.U.B. - ADMINISTRADOR')"/>
                        <input v-model="rolesmenu_administrador" :value="'admin-ub'"
                               :checked="true"
                               class="d-nones rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/> Admin-ub<br/>
                        <input v-model="rolesmenu_administrador" :value="'admin'"
                               :checked="true"
                               class="d-nones rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/> Admin
                      </th>
                    </tr>
                    <tr class="bg-secondary text-dark text-bold">
                      <td colspan="2">Inscripciones</td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-warning text-bold"></td>
                      <td class="text-center align-middle text-warning text-bold"></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Estudiantes Nuevos</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'inscripcion-nuevos'"
                               :checked="rolesmenu_cajas.includes('inscripcion-nuevos')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'inscripcion-nuevos'"
                               :checked="rolesmenu_registros.includes('inscripcion-nuevos')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'inscripcion-nuevos'"
                               :checked="rolesmenu_bienestar.includes('inscripcion-nuevos')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'inscripcion-nuevos'"
                               :checked="rolesmenu_jefe.includes('inscripcion-nuevos')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'inscripcion-nuevos'"
                               :checked="rolesmenu_sistemas.includes('inscripcion-nuevos')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'inscripcion-nuevos'"
                               :checked="rolesmenu_daf.includes('inscripcion-nuevos')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'inscripcion-nuevos'"
                               :checked="rolesmenu_postgrado.includes('inscripcion-nuevos')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'inscripcion-nuevos'"
                               :checked="rolesmenu_ea_cajas.includes('inscripcion-nuevos')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'inscripcion-nuevos'"
                               :checked="rolesmenu_ea_bienestar.includes('inscripcion-nuevos')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'inscripcion-nuevos'"
                               :checked="rolesmenu_ea_jefe.includes('inscripcion-nuevos')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'inscripcion-nuevos'"
                               :checked="rolesmenu_ea_vicerrector.includes('inscripcion-nuevos')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'inscripcion-nuevos'"
                               :checked="rolesmenu_directorio.includes('inscripcion-nuevos')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'inscripcion-nuevos'"
                               :checked="rolesmenu_administrador.includes('inscripcion-nuevos')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Estudiantes Regulares</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'inscripcion-regulares'"
                               :checked="rolesmenu_cajas.includes('inscripcion-regulares')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'inscripcion-regulares'"
                               :checked="rolesmenu_registros.includes('inscripcion-regulares')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'inscripcion-regulares'"
                               :checked="rolesmenu_bienestar.includes('inscripcion-regulares')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'inscripcion-regulares'"
                               :checked="rolesmenu_jefe.includes('inscripcion-regulares')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'inscripcion-regulares'"
                               :checked="rolesmenu_sistemas.includes('inscripcion-regulares')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'inscripcion-regulares'"
                               :checked="rolesmenu_daf.includes('inscripcion-regulares')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'inscripcion-regulares'"
                               :checked="rolesmenu_postgrado.includes('inscripcion-regulares')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'inscripcion-regulares'"
                               :checked="rolesmenu_ea_cajas.includes('inscripcion-regulares')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'inscripcion-regulares'"
                               :checked="rolesmenu_ea_bienestar.includes('inscripcion-regulares')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'inscripcion-regulares'"
                               :checked="rolesmenu_ea_jefe.includes('inscripcion-regulares')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'inscripcion-regulares'"
                               :checked="rolesmenu_ea_vicerrector.includes('inscripcion-regulares')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'inscripcion-regulares'"
                               :checked="rolesmenu_directorio.includes('inscripcion-regulares')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'inscripcion-regulares'"
                               :checked="rolesmenu_administrador.includes('inscripcion-regulares')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Documentos</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'documentos'"
                               :checked="rolesmenu_cajas.includes('documentos')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'documentos'"
                               :checked="rolesmenu_registros.includes('documentos')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'documentos'"
                               :checked="rolesmenu_bienestar.includes('documentos')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'documentos'"
                               :checked="rolesmenu_jefe.includes('documentos')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'documentos'"
                               :checked="rolesmenu_sistemas.includes('documentos')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'documentos'"
                               :checked="rolesmenu_daf.includes('documentos')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'documentos'"
                               :checked="rolesmenu_postgrado.includes('documentos')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'documentos'"
                               :checked="rolesmenu_ea_cajas.includes('documentos')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'documentos'"
                               :checked="rolesmenu_ea_bienestar.includes('documentos')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'documentos'"
                               :checked="rolesmenu_ea_jefe.includes('documentos')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'documentos'"
                               :checked="rolesmenu_ea_vicerrector.includes('documentos')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'documentos'"
                               :checked="rolesmenu_directorio.includes('documentos')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'documentos'"
                               :checked="rolesmenu_administrador.includes('documentos')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Compromisos</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'compromisos'"
                               :checked="rolesmenu_cajas.includes('compromisos')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'compromisos'"
                               :checked="rolesmenu_registros.includes('compromisos')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'compromisos'"
                               :checked="rolesmenu_bienestar.includes('compromisos')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'compromisos'"
                               :checked="rolesmenu_jefe.includes('compromisos')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'compromisos'"
                               :checked="rolesmenu_sistemas.includes('compromisos')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'compromisos'"
                               :checked="rolesmenu_daf.includes('compromisos')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'compromisos'"
                               :checked="rolesmenu_postgrado.includes('compromisos')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'compromisos'"
                               :checked="rolesmenu_ea_cajas.includes('compromisos')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'compromisos'"
                               :checked="rolesmenu_ea_bienestar.includes('compromisos')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'compromisos'"
                               :checked="rolesmenu_ea_jefe.includes('compromisos')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'compromisos'"
                               :checked="rolesmenu_ea_vicerrector.includes('compromisos')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'compromisos'"
                               :checked="rolesmenu_directorio.includes('compromisos')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'compromisos'"
                               :checked="rolesmenu_administrador.includes('compromisos')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Contratos / Boletas (Ver-Inv)</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'contratos'"
                               :checked="rolesmenu_cajas.includes('contratos')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'contratos'"
                               :checked="rolesmenu_registros.includes('contratos')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'contratos'"
                               :checked="rolesmenu_bienestar.includes('contratos')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'contratos'"
                               :checked="rolesmenu_jefe.includes('contratos')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'contratos'"
                               :checked="rolesmenu_sistemas.includes('contratos')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'contratos'"
                               :checked="rolesmenu_daf.includes('contratos')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'contratos'"
                               :checked="rolesmenu_postgrado.includes('contratos')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'contratos'"
                               :checked="rolesmenu_ea_cajas.includes('contratos')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'contratos'"
                               :checked="rolesmenu_ea_bienestar.includes('contratos')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'contratos'"
                               :checked="rolesmenu_ea_jefe.includes('contratos')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'contratos'"
                               :checked="rolesmenu_ea_vicerrector.includes('contratos')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'contratos'"
                               :checked="rolesmenu_directorio.includes('contratos')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'contratos'"
                               :checked="rolesmenu_administrador.includes('contratos')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Hoja de ruta</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'hojaruta'"
                               :checked="rolesmenu_cajas.includes('hojaruta')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'hojaruta'"
                               :checked="rolesmenu_registros.includes('hojaruta')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'hojaruta'"
                               :checked="rolesmenu_bienestar.includes('hojaruta')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'hojaruta'"
                               :checked="rolesmenu_jefe.includes('hojaruta')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'hojaruta'"
                               :checked="rolesmenu_sistemas.includes('hojaruta')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'hojaruta'"
                               :checked="rolesmenu_daf.includes('hojaruta')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'hojaruta'"
                               :checked="rolesmenu_postgrado.includes('hojaruta')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'hojaruta'"
                               :checked="rolesmenu_ea_cajas.includes('hojaruta')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'hojaruta'"
                               :checked="rolesmenu_ea_bienestar.includes('hojaruta')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'hojaruta'"
                               :checked="rolesmenu_ea_jefe.includes('hojaruta')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'hojaruta'"
                               :checked="rolesmenu_ea_vicerrector.includes('hojaruta')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'hojaruta'"
                               :checked="rolesmenu_directorio.includes('hojaruta')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'hojaruta'"
                               :checked="rolesmenu_administrador.includes('hojaruta')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr class="bg-secondary text-dark text-bold">
                      <td colspan="2">Facturacion en Linea (*)</td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-warning text-bold"></td>
                      <td class="text-center align-middle text-warning text-bold"></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Facturacion Estudiantes</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_cajas.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_registros.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_bienestar.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_jefe.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_sistemas.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_daf.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_postgrado.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_ea_cajas.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_ea_bienestar.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_ea_jefe.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_ea_vicerrector.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_directorio.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_administrador.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Facturacion Cli. Externos</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_cajas.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_registros.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_bienestar.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_jefe.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_sistemas.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_daf.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_postgrado.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_ea_cajas.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_ea_bienestar.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_ea_jefe.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_ea_vicerrector.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_directorio.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'facturacion-en-linea'"
                               :checked="rolesmenu_administrador.includes('facturacion-en-linea')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr class="bg-secondary text-dark text-bold">
                      <td colspan="2">Facturacion Manual (de Contingencia)</td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-warning text-bold"></td>
                      <td class="text-center align-middle text-warning text-bold"></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Estudiantes</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_cajas.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_registros.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_bienestar.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_jefe.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_sistemas.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_daf.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_postgrado.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_ea_cajas.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_ea_bienestar.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_ea_jefe.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_ea_vicerrector.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_directorio.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_administrador.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>clientes Externos</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_cajas.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_registros.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_bienestar.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_jefe.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_sistemas.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_daf.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_postgrado.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_ea_cajas.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_ea_bienestar.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_ea_jefe.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_ea_vicerrector.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_directorio.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'facturacion-en-linea-manual'"
                               :checked="rolesmenu_administrador.includes('facturacion-en-linea-manual')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr class="bg-secondary text-dark text-bold">
                      <td colspan="2">Facturacion Manual (Talonario)</td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-warning text-bold"></td>
                      <td class="text-center align-middle text-warning text-bold"></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Estudiantes</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'facturacion-manual'"
                               :checked="rolesmenu_cajas.includes('facturacion-manual')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'facturacion-manual'"
                               :checked="rolesmenu_registros.includes('facturacion-manual')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'facturacion-manual'"
                               :checked="rolesmenu_bienestar.includes('facturacion-manual')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'facturacion-manual'"
                               :checked="rolesmenu_jefe.includes('facturacion-manual')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'facturacion-manual'"
                               :checked="rolesmenu_sistemas.includes('facturacion-manual')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'facturacion-manual'"
                               :checked="rolesmenu_daf.includes('facturacion-manual')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'facturacion-manual'"
                               :checked="rolesmenu_postgrado.includes('facturacion-manual')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'facturacion-manual'"
                               :checked="rolesmenu_ea_cajas.includes('facturacion-manual')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'facturacion-manual'"
                               :checked="rolesmenu_ea_bienestar.includes('facturacion-manual')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'facturacion-manual'"
                               :checked="rolesmenu_ea_jefe.includes('facturacion-manual')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'facturacion-manual'"
                               :checked="rolesmenu_ea_vicerrector.includes('facturacion-manual')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'facturacion-manual'"
                               :checked="rolesmenu_directorio.includes('facturacion-manual')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'facturacion-manual'"
                               :checked="rolesmenu_administrador.includes('facturacion-manual')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>clientes Externos</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'facturacion-manual'"
                               :checked="rolesmenu_cajas.includes('facturacion-manual')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'facturacion-manual'"
                               :checked="rolesmenu_registros.includes('facturacion-manual')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'facturacion-manual'"
                               :checked="rolesmenu_bienestar.includes('facturacion-manual')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'facturacion-manual'"
                               :checked="rolesmenu_jefe.includes('facturacion-manual')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'facturacion-manual'"
                               :checked="rolesmenu_sistemas.includes('facturacion-manual')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'facturacion-manual'"
                               :checked="rolesmenu_daf.includes('facturacion-manual')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'facturacion-manual'"
                               :checked="rolesmenu_postgrado.includes('facturacion-manual')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'facturacion-manual'"
                               :checked="rolesmenu_ea_cajas.includes('facturacion-manual')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'facturacion-manual'"
                               :checked="rolesmenu_ea_bienestar.includes('facturacion-manual')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'facturacion-manual'"
                               :checked="rolesmenu_ea_jefe.includes('facturacion-manual')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'facturacion-manual'"
                               :checked="rolesmenu_ea_vicerrector.includes('facturacion-manual')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'facturacion-manual'"
                               :checked="rolesmenu_directorio.includes('facturacion-manual')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'facturacion-manual'"
                               :checked="rolesmenu_administrador.includes('facturacion-manual')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr class="bg-secondary text-dark text-bold">
                      <td colspan="2">Reportes ></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-warning text-bold"></td>
                      <td class="text-center align-middle text-warning text-bold"></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Reportes de Estudiantes ></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-warning text-bold"></td>
                      <td class="text-center align-middle text-warning text-bold"></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>&nbsp; &nbsp; Boletín de calificaciones</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'rpt_boletin_calificaciones'"
                               :checked="rolesmenu_cajas.includes('rpt_boletin_calificaciones')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'rpt_boletin_calificaciones'"
                               :checked="rolesmenu_registros.includes('rpt_boletin_calificaciones')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'rpt_boletin_calificaciones'"
                               :checked="rolesmenu_bienestar.includes('rpt_boletin_calificaciones')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'rpt_boletin_calificaciones'"
                               :checked="rolesmenu_jefe.includes('rpt_boletin_calificaciones')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'rpt_boletin_calificaciones'"
                               :checked="rolesmenu_sistemas.includes('rpt_boletin_calificaciones')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'rpt_boletin_calificaciones'"
                               :checked="rolesmenu_daf.includes('rpt_boletin_calificaciones')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'rpt_boletin_calificaciones'"
                               :checked="rolesmenu_postgrado.includes('rpt_boletin_calificaciones')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'rpt_boletin_calificaciones'"
                               :checked="rolesmenu_ea_cajas.includes('rpt_boletin_calificaciones')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'rpt_boletin_calificaciones'"
                               :checked="rolesmenu_ea_bienestar.includes('rpt_boletin_calificaciones')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'rpt_boletin_calificaciones'"
                               :checked="rolesmenu_ea_jefe.includes('rpt_boletin_calificaciones')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'rpt_boletin_calificaciones'"
                               :checked="rolesmenu_ea_vicerrector.includes('rpt_boletin_calificaciones')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'rpt_boletin_calificaciones'"
                               :checked="rolesmenu_directorio.includes('rpt_boletin_calificaciones')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'rpt_boletin_calificaciones'"
                               :checked="rolesmenu_administrador.includes('rpt_boletin_calificaciones')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>&nbsp; &nbsp; Boleta habilitación a examen</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'rpt_boleta_examen'"
                               :checked="rolesmenu_cajas.includes('rpt_boleta_examen')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'rpt_boleta_examen'"
                               :checked="rolesmenu_registros.includes('rpt_boleta_examen')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'rpt_boleta_examen'"
                               :checked="rolesmenu_bienestar.includes('rpt_boleta_examen')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'rpt_boleta_examen'"
                               :checked="rolesmenu_jefe.includes('rpt_boleta_examen')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'rpt_boleta_examen'"
                               :checked="rolesmenu_sistemas.includes('rpt_boleta_examen')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'rpt_boleta_examen'"
                               :checked="rolesmenu_daf.includes('rpt_boleta_examen')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'rpt_boleta_examen'"
                               :checked="rolesmenu_postgrado.includes('rpt_boleta_examen')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'rpt_boleta_examen'"
                               :checked="rolesmenu_ea_cajas.includes('rpt_boleta_examen')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'rpt_boleta_examen'"
                               :checked="rolesmenu_ea_bienestar.includes('rpt_boleta_examen')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'rpt_boleta_examen'"
                               :checked="rolesmenu_ea_jefe.includes('rpt_boleta_examen')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'rpt_boleta_examen'"
                               :checked="rolesmenu_ea_vicerrector.includes('rpt_boleta_examen')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'rpt_boleta_examen'"
                               :checked="rolesmenu_directorio.includes('rpt_boleta_examen')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'rpt_boleta_examen'"
                               :checked="rolesmenu_administrador.includes('rpt_boleta_examen')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>&nbsp; &nbsp; Historial académico</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'rpt_historial_academico'"
                               :checked="rolesmenu_cajas.includes('rpt_historial_academico')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'rpt_historial_academico'"
                               :checked="rolesmenu_registros.includes('rpt_historial_academico')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'rpt_historial_academico'"
                               :checked="rolesmenu_bienestar.includes('rpt_historial_academico')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'rpt_historial_academico'"
                               :checked="rolesmenu_jefe.includes('rpt_historial_academico')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'rpt_historial_academico'"
                               :checked="rolesmenu_sistemas.includes('rpt_historial_academico')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'rpt_historial_academico'"
                               :checked="rolesmenu_daf.includes('rpt_historial_academico')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'rpt_historial_academico'"
                               :checked="rolesmenu_postgrado.includes('rpt_historial_academico')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'rpt_historial_academico'"
                               :checked="rolesmenu_ea_cajas.includes('rpt_historial_academico')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'rpt_historial_academico'"
                               :checked="rolesmenu_ea_bienestar.includes('rpt_historial_academico')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'rpt_historial_academico'"
                               :checked="rolesmenu_ea_jefe.includes('rpt_historial_academico')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'rpt_historial_academico'"
                               :checked="rolesmenu_ea_vicerrector.includes('rpt_historial_academico')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'rpt_historial_academico'"
                               :checked="rolesmenu_directorio.includes('rpt_historial_academico')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'rpt_historial_academico'"
                               :checked="rolesmenu_administrador.includes('rpt_historial_academico')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>&nbsp; &nbsp; Certificado de notas</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'rpt_certificado_notas'"
                               :checked="rolesmenu_cajas.includes('rpt_certificado_notas')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'rpt_certificado_notas'"
                               :checked="rolesmenu_registros.includes('rpt_certificado_notas')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'rpt_certificado_notas'"
                               :checked="rolesmenu_bienestar.includes('rpt_certificado_notas')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'rpt_certificado_notas'"
                               :checked="rolesmenu_jefe.includes('rpt_certificado_notas')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'rpt_certificado_notas'"
                               :checked="rolesmenu_sistemas.includes('rpt_certificado_notas')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'rpt_certificado_notas'"
                               :checked="rolesmenu_daf.includes('rpt_certificado_notas')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'rpt_certificado_notas'"
                               :checked="rolesmenu_postgrado.includes('rpt_certificado_notas')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'rpt_certificado_notas'"
                               :checked="rolesmenu_ea_cajas.includes('rpt_certificado_notas')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'rpt_certificado_notas'"
                               :checked="rolesmenu_ea_bienestar.includes('rpt_certificado_notas')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'rpt_certificado_notas'"
                               :checked="rolesmenu_ea_jefe.includes('rpt_certificado_notas')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'rpt_certificado_notas'"
                               :checked="rolesmenu_ea_vicerrector.includes('rpt_certificado_notas')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'rpt_certificado_notas'"
                               :checked="rolesmenu_directorio.includes('rpt_certificado_notas')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'rpt_certificado_notas'"
                               :checked="rolesmenu_administrador.includes('rpt_certificado_notas')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>&nbsp; &nbsp; Diploma académico</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'rpt_diploma_academico'"
                               :checked="rolesmenu_cajas.includes('rpt_diploma_academico')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'rpt_diploma_academico'"
                               :checked="rolesmenu_registros.includes('rpt_diploma_academico')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'rpt_diploma_academico'"
                               :checked="rolesmenu_bienestar.includes('rpt_diploma_academico')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'rpt_diploma_academico'"
                               :checked="rolesmenu_jefe.includes('rpt_diploma_academico')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'rpt_diploma_academico'"
                               :checked="rolesmenu_sistemas.includes('rpt_diploma_academico')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'rpt_diploma_academico'"
                               :checked="rolesmenu_daf.includes('rpt_diploma_academico')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'rpt_diploma_academico'"
                               :checked="rolesmenu_postgrado.includes('rpt_diploma_academico')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'rpt_diploma_academico'"
                               :checked="rolesmenu_ea_cajas.includes('rpt_diploma_academico')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'rpt_diploma_academico'"
                               :checked="rolesmenu_ea_bienestar.includes('rpt_diploma_academico')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'rpt_diploma_academico'"
                               :checked="rolesmenu_ea_jefe.includes('rpt_diploma_academico')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'rpt_diploma_academico'"
                               :checked="rolesmenu_ea_vicerrector.includes('rpt_diploma_academico')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'rpt_diploma_academico'"
                               :checked="rolesmenu_directorio.includes('rpt_diploma_academico')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'rpt_diploma_academico'"
                               :checked="rolesmenu_administrador.includes('rpt_diploma_academico')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>&nbsp; &nbsp; Planilla de calificaciones</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'rpt_planilla_calificaciones'"
                               :checked="rolesmenu_cajas.includes('rpt_planilla_calificaciones')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'rpt_planilla_calificaciones'"
                               :checked="rolesmenu_registros.includes('rpt_planilla_calificaciones')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'rpt_planilla_calificaciones'"
                               :checked="rolesmenu_bienestar.includes('rpt_planilla_calificaciones')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'rpt_planilla_calificaciones'"
                               :checked="rolesmenu_jefe.includes('rpt_planilla_calificaciones')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'rpt_planilla_calificaciones'"
                               :checked="rolesmenu_sistemas.includes('rpt_planilla_calificaciones')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'rpt_planilla_calificaciones'"
                               :checked="rolesmenu_daf.includes('rpt_planilla_calificaciones')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'rpt_planilla_calificaciones'"
                               :checked="rolesmenu_postgrado.includes('rpt_planilla_calificaciones')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'rpt_planilla_calificaciones'"
                               :checked="rolesmenu_ea_cajas.includes('rpt_planilla_calificaciones')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'rpt_planilla_calificaciones'"
                               :checked="rolesmenu_ea_bienestar.includes('rpt_planilla_calificaciones')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'rpt_planilla_calificaciones'"
                               :checked="rolesmenu_ea_jefe.includes('rpt_planilla_calificaciones')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'rpt_planilla_calificaciones'"
                               :checked="rolesmenu_ea_vicerrector.includes('rpt_planilla_calificaciones')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'rpt_planilla_calificaciones'"
                               :checked="rolesmenu_directorio.includes('rpt_planilla_calificaciones')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'rpt_planilla_calificaciones'"
                               :checked="rolesmenu_administrador.includes('rpt_planilla_calificaciones')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Reporte de Facturación</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'rpt_facturacion'"
                               :checked="rolesmenu_cajas.includes('rpt_facturacion')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'rpt_facturacion'"
                               :checked="rolesmenu_registros.includes('rpt_facturacion')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'rpt_facturacion'"
                               :checked="rolesmenu_bienestar.includes('rpt_facturacion')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'rpt_facturacion'"
                               :checked="rolesmenu_jefe.includes('rpt_facturacion')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'rpt_facturacion'"
                               :checked="rolesmenu_sistemas.includes('rpt_facturacion')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'rpt_facturacion'"
                               :checked="rolesmenu_daf.includes('rpt_facturacion')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'rpt_facturacion'"
                               :checked="rolesmenu_postgrado.includes('rpt_facturacion')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'rpt_facturacion'"
                               :checked="rolesmenu_ea_cajas.includes('rpt_facturacion')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'rpt_facturacion'"
                               :checked="rolesmenu_ea_bienestar.includes('rpt_facturacion')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'rpt_facturacion'"
                               :checked="rolesmenu_ea_jefe.includes('rpt_facturacion')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'rpt_facturacion'"
                               :checked="rolesmenu_ea_vicerrector.includes('rpt_facturacion')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'rpt_facturacion'"
                               :checked="rolesmenu_directorio.includes('rpt_facturacion')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'rpt_facturacion'"
                               :checked="rolesmenu_administrador.includes('rpt_facturacion')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Pagos por Estudiante</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'rpt_pagos_estudiante'"
                               :checked="rolesmenu_cajas.includes('rpt_pagos_estudiante')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'rpt_pagos_estudiante'"
                               :checked="rolesmenu_registros.includes('rpt_pagos_estudiante')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'rpt_pagos_estudiante'"
                               :checked="rolesmenu_bienestar.includes('rpt_pagos_estudiante')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'rpt_pagos_estudiante'"
                               :checked="rolesmenu_jefe.includes('rpt_pagos_estudiante')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'rpt_pagos_estudiante'"
                               :checked="rolesmenu_sistemas.includes('rpt_pagos_estudiante')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'rpt_pagos_estudiante'"
                               :checked="rolesmenu_daf.includes('rpt_pagos_estudiante')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'rpt_pagos_estudiante'"
                               :checked="rolesmenu_postgrado.includes('rpt_pagos_estudiante')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'rpt_pagos_estudiante'"
                               :checked="rolesmenu_ea_cajas.includes('rpt_pagos_estudiante')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'rpt_pagos_estudiante'"
                               :checked="rolesmenu_ea_bienestar.includes('rpt_pagos_estudiante')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'rpt_pagos_estudiante'"
                               :checked="rolesmenu_ea_jefe.includes('rpt_pagos_estudiante')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'rpt_pagos_estudiante'"
                               :checked="rolesmenu_ea_vicerrector.includes('rpt_pagos_estudiante')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'rpt_pagos_estudiante'"
                               :checked="rolesmenu_directorio.includes('rpt_pagos_estudiante')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'rpt_pagos_estudiante'"
                               :checked="rolesmenu_administrador.includes('rpt_pagos_estudiante')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Matriculados por Gestión</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'rpt_matriculados_gestion'"
                               :checked="rolesmenu_cajas.includes('rpt_matriculados_gestion')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'rpt_matriculados_gestion'"
                               :checked="rolesmenu_registros.includes('rpt_matriculados_gestion')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'rpt_matriculados_gestion'"
                               :checked="rolesmenu_bienestar.includes('rpt_matriculados_gestion')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'rpt_matriculados_gestion'"
                               :checked="rolesmenu_jefe.includes('rpt_matriculados_gestion')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'rpt_matriculados_gestion'"
                               :checked="rolesmenu_sistemas.includes('rpt_matriculados_gestion')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'rpt_matriculados_gestion'"
                               :checked="rolesmenu_daf.includes('rpt_matriculados_gestion')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'rpt_matriculados_gestion'"
                               :checked="rolesmenu_postgrado.includes('rpt_matriculados_gestion')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'rpt_matriculados_gestion'"
                               :checked="rolesmenu_ea_cajas.includes('rpt_matriculados_gestion')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'rpt_matriculados_gestion'"
                               :checked="rolesmenu_ea_bienestar.includes('rpt_matriculados_gestion')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'rpt_matriculados_gestion'"
                               :checked="rolesmenu_ea_jefe.includes('rpt_matriculados_gestion')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'rpt_matriculados_gestion'"
                               :checked="rolesmenu_ea_vicerrector.includes('rpt_matriculados_gestion')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'rpt_matriculados_gestion'"
                               :checked="rolesmenu_directorio.includes('rpt_matriculados_gestion')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'rpt_matriculados_gestion'"
                               :checked="rolesmenu_administrador.includes('rpt_matriculados_gestion')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Estudiantes por Materia</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'rpt_estudiantes_materia'"
                               :checked="rolesmenu_cajas.includes('rpt_estudiantes_materia')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'rpt_estudiantes_materia'"
                               :checked="rolesmenu_registros.includes('rpt_estudiantes_materia')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'rpt_estudiantes_materia'"
                               :checked="rolesmenu_bienestar.includes('rpt_estudiantes_materia')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'rpt_estudiantes_materia'"
                               :checked="rolesmenu_jefe.includes('rpt_estudiantes_materia')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'rpt_estudiantes_materia'"
                               :checked="rolesmenu_sistemas.includes('rpt_estudiantes_materia')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'rpt_estudiantes_materia'"
                               :checked="rolesmenu_daf.includes('rpt_estudiantes_materia')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'rpt_estudiantes_materia'"
                               :checked="rolesmenu_postgrado.includes('rpt_estudiantes_materia')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'rpt_estudiantes_materia'"
                               :checked="rolesmenu_ea_cajas.includes('rpt_estudiantes_materia')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'rpt_estudiantes_materia'"
                               :checked="rolesmenu_ea_bienestar.includes('rpt_estudiantes_materia')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'rpt_estudiantes_materia'"
                               :checked="rolesmenu_ea_jefe.includes('rpt_estudiantes_materia')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'rpt_estudiantes_materia'"
                               :checked="rolesmenu_ea_vicerrector.includes('rpt_estudiantes_materia')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'rpt_estudiantes_materia'"
                               :checked="rolesmenu_directorio.includes('rpt_estudiantes_materia')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'rpt_estudiantes_materia'"
                               :checked="rolesmenu_administrador.includes('rpt_estudiantes_materia')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Estudiantes por Beca</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'rpt_estudiantes_beca'"
                               :checked="rolesmenu_cajas.includes('rpt_estudiantes_beca')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'rpt_estudiantes_beca'"
                               :checked="rolesmenu_registros.includes('rpt_estudiantes_beca')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'rpt_estudiantes_beca'"
                               :checked="rolesmenu_bienestar.includes('rpt_estudiantes_beca')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'rpt_estudiantes_beca'"
                               :checked="rolesmenu_jefe.includes('rpt_estudiantes_beca')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'rpt_estudiantes_beca'"
                               :checked="rolesmenu_sistemas.includes('rpt_estudiantes_beca')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'rpt_estudiantes_beca'"
                               :checked="rolesmenu_daf.includes('rpt_estudiantes_beca')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'rpt_estudiantes_beca'"
                               :checked="rolesmenu_postgrado.includes('rpt_estudiantes_beca')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'rpt_estudiantes_beca'"
                               :checked="rolesmenu_ea_cajas.includes('rpt_estudiantes_beca')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'rpt_estudiantes_beca'"
                               :checked="rolesmenu_ea_bienestar.includes('rpt_estudiantes_beca')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'rpt_estudiantes_beca'"
                               :checked="rolesmenu_ea_jefe.includes('rpt_estudiantes_beca')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'rpt_estudiantes_beca'"
                               :checked="rolesmenu_ea_vicerrector.includes('rpt_estudiantes_beca')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'rpt_estudiantes_beca'"
                               :checked="rolesmenu_directorio.includes('rpt_estudiantes_beca')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'rpt_estudiantes_beca'"
                               :checked="rolesmenu_administrador.includes('rpt_estudiantes_beca')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr class="bg-secondary text-dark text-bold">
                      <td colspan="2">Notas - Academico</td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-warning text-bold"></td>
                      <td class="text-center align-middle text-warning text-bold"></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Notas - Administrador</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'docente-notas-administrador'"
                               :checked="rolesmenu_cajas.includes('docente-notas-administrador')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'docente-notas-administrador'"
                               :checked="rolesmenu_registros.includes('docente-notas-administrador')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'docente-notas-administrador'"
                               :checked="rolesmenu_bienestar.includes('docente-notas-administrador')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'docente-notas-administrador'"
                               :checked="rolesmenu_jefe.includes('docente-notas-administrador')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'docente-notas-administrador'"
                               :checked="rolesmenu_sistemas.includes('docente-notas-administrador')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'docente-notas-administrador'"
                               :checked="rolesmenu_daf.includes('docente-notas-administrador')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'docente-notas-administrador'"
                               :checked="rolesmenu_postgrado.includes('docente-notas-administrador')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'docente-notas-administrador'"
                               :checked="rolesmenu_ea_cajas.includes('docente-notas-administrador')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'docente-notas-administrador'"
                               :checked="rolesmenu_ea_bienestar.includes('docente-notas-administrador')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'docente-notas-administrador'"
                               :checked="rolesmenu_ea_jefe.includes('docente-notas-administrador')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'docente-notas-administrador'"
                               :checked="rolesmenu_ea_vicerrector.includes('docente-notas-administrador')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'docente-notas-administrador'"
                               :checked="rolesmenu_directorio.includes('docente-notas-administrador')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'docente-notas-administrador'"
                               :checked="rolesmenu_administrador.includes('docente-notas-administrador')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Jefes de carrera - Designación</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'docente-designa-materia'"
                               :checked="rolesmenu_cajas.includes('docente-designa-materia')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'docente-designa-materia'"
                               :checked="rolesmenu_registros.includes('docente-designa-materia')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'docente-designa-materia'"
                               :checked="rolesmenu_bienestar.includes('docente-designa-materia')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'docente-designa-materia'"
                               :checked="rolesmenu_jefe.includes('docente-designa-materia')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'docente-designa-materia'"
                               :checked="rolesmenu_sistemas.includes('docente-designa-materia')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'docente-designa-materia'"
                               :checked="rolesmenu_daf.includes('docente-designa-materia')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'docente-designa-materia'"
                               :checked="rolesmenu_postgrado.includes('docente-designa-materia')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'docente-designa-materia'"
                               :checked="rolesmenu_ea_cajas.includes('docente-designa-materia')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'docente-designa-materia'"
                               :checked="rolesmenu_ea_bienestar.includes('docente-designa-materia')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'docente-designa-materia'"
                               :checked="rolesmenu_ea_jefe.includes('docente-designa-materia')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'docente-designa-materia'"
                               :checked="rolesmenu_ea_vicerrector.includes('docente-designa-materia')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'docente-designa-materia'"
                               :checked="rolesmenu_directorio.includes('docente-designa-materia')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'docente-designa-materia'"
                               :checked="rolesmenu_administrador.includes('docente-designa-materia')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Notas - Docentes</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'docente-notas'"
                               :checked="rolesmenu_cajas.includes('docente-notas')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'docente-notas'"
                               :checked="rolesmenu_registros.includes('docente-notas')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'docente-notas'"
                               :checked="rolesmenu_bienestar.includes('docente-notas')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'docente-notas'"
                               :checked="rolesmenu_jefe.includes('docente-notas')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'docente-notas'"
                               :checked="rolesmenu_sistemas.includes('docente-notas')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'docente-notas'"
                               :checked="rolesmenu_daf.includes('docente-notas')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'docente-notas'"
                               :checked="rolesmenu_postgrado.includes('docente-notas')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'docente-notas'"
                               :checked="rolesmenu_ea_cajas.includes('docente-notas')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'docente-notas'"
                               :checked="rolesmenu_ea_bienestar.includes('docente-notas')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'docente-notas'"
                               :checked="rolesmenu_ea_jefe.includes('docente-notas')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'docente-notas'"
                               :checked="rolesmenu_ea_vicerrector.includes('docente-notas')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'docente-notas'"
                               :checked="rolesmenu_directorio.includes('docente-notas')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'docente-notas'"
                               :checked="rolesmenu_administrador.includes('docente-notas')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Docentes-Usuarios</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'docente-administrador'"
                               :checked="rolesmenu_cajas.includes('docente-administrador')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'docente-administrador'"
                               :checked="rolesmenu_registros.includes('docente-administrador')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'docente-administrador'"
                               :checked="rolesmenu_bienestar.includes('docente-administrador')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'docente-administrador'"
                               :checked="rolesmenu_jefe.includes('docente-administrador')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'docente-administrador'"
                               :checked="rolesmenu_sistemas.includes('docente-administrador')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'docente-administrador'"
                               :checked="rolesmenu_daf.includes('docente-administrador')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'docente-administrador'"
                               :checked="rolesmenu_postgrado.includes('docente-administrador')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'docente-administrador'"
                               :checked="rolesmenu_ea_cajas.includes('docente-administrador')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'docente-administrador'"
                               :checked="rolesmenu_ea_bienestar.includes('docente-administrador')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'docente-administrador'"
                               :checked="rolesmenu_ea_jefe.includes('docente-administrador')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'docente-administrador'"
                               :checked="rolesmenu_ea_vicerrector.includes('docente-administrador')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'docente-administrador'"
                               :checked="rolesmenu_directorio.includes('docente-administrador')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'docente-administrador'"
                               :checked="rolesmenu_administrador.includes('docente-administrador')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Estudiantes-Usuarios</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'estudiantes'"
                               :checked="rolesmenu_cajas.includes('estudiantes')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'estudiantes'"
                               :checked="rolesmenu_registros.includes('estudiantes')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'estudiantes'"
                               :checked="rolesmenu_bienestar.includes('estudiantes')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'estudiantes'"
                               :checked="rolesmenu_jefe.includes('estudiantes')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'estudiantes'"
                               :checked="rolesmenu_sistemas.includes('estudiantes')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'estudiantes'"
                               :checked="rolesmenu_daf.includes('estudiantes')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'estudiantes'"
                               :checked="rolesmenu_postgrado.includes('estudiantes')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'estudiantes'"
                               :checked="rolesmenu_ea_cajas.includes('estudiantes')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'estudiantes'"
                               :checked="rolesmenu_ea_bienestar.includes('estudiantes')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'estudiantes'"
                               :checked="rolesmenu_ea_jefe.includes('estudiantes')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'estudiantes'"
                               :checked="rolesmenu_ea_vicerrector.includes('estudiantes')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'estudiantes'"
                               :checked="rolesmenu_directorio.includes('estudiantes')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'estudiantes'"
                               :checked="rolesmenu_administrador.includes('estudiantes')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr class="bg-secondary text-dark text-bold">
                      <td colspan="2">Carnet Universitario</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'credencial'"
                               :checked="rolesmenu_cajas.includes('credencial')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'credencial'"
                               :checked="rolesmenu_registros.includes('credencial')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'credencial'"
                               :checked="rolesmenu_bienestar.includes('credencial')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'credencial'"
                               :checked="rolesmenu_jefe.includes('credencial')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'credencial'"
                               :checked="rolesmenu_sistemas.includes('credencial')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'credencial'"
                               :checked="rolesmenu_daf.includes('credencial')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'credencial'"
                               :checked="rolesmenu_postgrado.includes('credencial')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'credencial'"
                               :checked="rolesmenu_ea_cajas.includes('credencial')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'credencial'"
                               :checked="rolesmenu_ea_bienestar.includes('credencial')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'credencial'"
                               :checked="rolesmenu_ea_jefe.includes('credencial')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'credencial'"
                               :checked="rolesmenu_ea_vicerrector.includes('credencial')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'credencial'"
                               :checked="rolesmenu_directorio.includes('credencial')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'credencial'"
                               :checked="rolesmenu_administrador.includes('credencial')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr class="bg-secondary text-dark text-bold">
                      <td colspan="2">Configuración</td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-success text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-primary text-bold"></td>
                      <td class="text-center align-middle text-warning text-bold"></td>
                      <td class="text-center align-middle text-warning text-bold"></td>
                    </tr>
                    <tr>
                      <td rowspan="12"></td>
                      <td>Calendarios de pagos</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'inscripcion-calendario-pagos'"
                               :checked="rolesmenu_cajas.includes('inscripcion-calendario-pagos')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'inscripcion-calendario-pagos'"
                               :checked="rolesmenu_registros.includes('inscripcion-calendario-pagos')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'inscripcion-calendario-pagos'"
                               :checked="rolesmenu_bienestar.includes('inscripcion-calendario-pagos')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'inscripcion-calendario-pagos'"
                               :checked="rolesmenu_jefe.includes('inscripcion-calendario-pagos')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'inscripcion-calendario-pagos'"
                               :checked="rolesmenu_sistemas.includes('inscripcion-calendario-pagos')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'inscripcion-calendario-pagos'"
                               :checked="rolesmenu_daf.includes('inscripcion-calendario-pagos')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'inscripcion-calendario-pagos'"
                               :checked="rolesmenu_postgrado.includes('inscripcion-calendario-pagos')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'inscripcion-calendario-pagos'"
                               :checked="rolesmenu_ea_cajas.includes('inscripcion-calendario-pagos')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'inscripcion-calendario-pagos'"
                               :checked="rolesmenu_ea_bienestar.includes('inscripcion-calendario-pagos')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'inscripcion-calendario-pagos'"
                               :checked="rolesmenu_ea_jefe.includes('inscripcion-calendario-pagos')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'inscripcion-calendario-pagos'"
                               :checked="rolesmenu_ea_vicerrector.includes('inscripcion-calendario-pagos')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'inscripcion-calendario-pagos'"
                               :checked="rolesmenu_directorio.includes('inscripcion-calendario-pagos')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'inscripcion-calendario-pagos'"
                               :checked="rolesmenu_administrador.includes('inscripcion-calendario-pagos')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td>Becas-Configuracion</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'becas_configuracion'"
                               :checked="rolesmenu_cajas.includes('becas_configuracion')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'becas_configuracion'"
                               :checked="rolesmenu_registros.includes('becas_configuracion')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'becas_configuracion'"
                               :checked="rolesmenu_bienestar.includes('becas_configuracion')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'becas_configuracion'"
                               :checked="rolesmenu_jefe.includes('becas_configuracion')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'becas_configuracion'"
                               :checked="rolesmenu_sistemas.includes('becas_configuracion')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'becas_configuracion'"
                               :checked="rolesmenu_daf.includes('becas_configuracion')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'becas_configuracion'"
                               :checked="rolesmenu_postgrado.includes('becas_configuracion')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'becas_configuracion'"
                               :checked="rolesmenu_ea_cajas.includes('becas_configuracion')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'becas_configuracion'"
                               :checked="rolesmenu_ea_bienestar.includes('becas_configuracion')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'becas_configuracion'"
                               :checked="rolesmenu_ea_jefe.includes('becas_configuracion')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'becas_configuracion'"
                               :checked="rolesmenu_ea_vicerrector.includes('becas_configuracion')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'becas_configuracion'"
                               :checked="rolesmenu_directorio.includes('becas_configuracion')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'becas_configuracion'"
                               :checked="rolesmenu_administrador.includes('becas_configuracion')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td>Usuarios</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'usuarios_administrador'"
                               :checked="rolesmenu_cajas.includes('usuarios_administrador')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'usuarios_administrador'"
                               :checked="rolesmenu_registros.includes('usuarios_administrador')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'usuarios_administrador'"
                               :checked="rolesmenu_bienestar.includes('usuarios_administrador')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'usuarios_administrador'"
                               :checked="rolesmenu_jefe.includes('usuarios_administrador')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'usuarios_administrador'"
                               :checked="rolesmenu_sistemas.includes('usuarios_administrador')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'usuarios_administrador'"
                               :checked="rolesmenu_daf.includes('usuarios_administrador')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'usuarios_administrador'"
                               :checked="rolesmenu_postgrado.includes('usuarios_administrador')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'usuarios_administrador'"
                               :checked="rolesmenu_ea_cajas.includes('usuarios_administrador')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'usuarios_administrador'"
                               :checked="rolesmenu_ea_bienestar.includes('usuarios_administrador')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'usuarios_administrador'"
                               :checked="rolesmenu_ea_jefe.includes('usuarios_administrador')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'usuarios_administrador'"
                               :checked="rolesmenu_ea_vicerrector.includes('usuarios_administrador')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'usuarios_administrador'"
                               :checked="rolesmenu_directorio.includes('usuarios_administrador')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'usuarios_administrador'"
                               :checked="rolesmenu_administrador.includes('usuarios_administrador')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td>Menu</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'admin-ub'"
                               :checked="rolesmenu_cajas.includes('admin-ub')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'admin-ub'"
                               :checked="rolesmenu_registros.includes('admin-ub')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'admin-ub'"
                               :checked="rolesmenu_bienestar.includes('admin-ub')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'admin-ub'"
                               :checked="rolesmenu_jefe.includes('admin-ub')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'admin-ub'"
                               :checked="rolesmenu_sistemas.includes('admin-ub')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'admin-ub'"
                               :checked="rolesmenu_daf.includes('admin-ub')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'admin-ub'"
                               :checked="rolesmenu_postgrado.includes('admin-ub')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'admin-ub'"
                               :checked="rolesmenu_ea_cajas.includes('admin-ub')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'admin-ub'"
                               :checked="rolesmenu_ea_bienestar.includes('admin-ub')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'admin-ub'"
                               :checked="rolesmenu_ea_jefe.includes('admin-ub')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'admin-ub'"
                               :checked="rolesmenu_ea_vicerrector.includes('admin-ub')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'admin-ub'"
                               :checked="rolesmenu_directorio.includes('admin-ub')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'admin-ub'"
                               :checked="rolesmenu_administrador.includes('admin-ub')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td>Roles</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'admin-ub'"
                               :checked="rolesmenu_cajas.includes('admin-ub')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'admin-ub'"
                               :checked="rolesmenu_registros.includes('admin-ub')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'admin-ub'"
                               :checked="rolesmenu_bienestar.includes('admin-ub')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'admin-ub'"
                               :checked="rolesmenu_jefe.includes('admin-ub')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'admin-ub'"
                               :checked="rolesmenu_sistemas.includes('admin-ub')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'admin-ub'"
                               :checked="rolesmenu_daf.includes('admin-ub')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'admin-ub'"
                               :checked="rolesmenu_postgrado.includes('admin-ub')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'admin-ub'"
                               :checked="rolesmenu_ea_cajas.includes('admin-ub')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'admin-ub'"
                               :checked="rolesmenu_ea_bienestar.includes('admin-ub')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'admin-ub'"
                               :checked="rolesmenu_ea_jefe.includes('admin-ub')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'admin-ub'"
                               :checked="rolesmenu_ea_vicerrector.includes('admin-ub')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'admin-ub'"
                               :checked="rolesmenu_directorio.includes('admin-ub')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'admin-ub'"
                               :checked="rolesmenu_administrador.includes('admin-ub')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td>Facultades</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'admin-ub'"
                               :checked="rolesmenu_cajas.includes('admin-ub')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'admin-ub'"
                               :checked="rolesmenu_registros.includes('admin-ub')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'admin-ub'"
                               :checked="rolesmenu_bienestar.includes('admin-ub')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'admin-ub'"
                               :checked="rolesmenu_jefe.includes('admin-ub')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'admin-ub'"
                               :checked="rolesmenu_sistemas.includes('admin-ub')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'admin-ub'"
                               :checked="rolesmenu_daf.includes('admin-ub')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'admin-ub'"
                               :checked="rolesmenu_postgrado.includes('admin-ub')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'admin-ub'"
                               :checked="rolesmenu_ea_cajas.includes('admin-ub')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'admin-ub'"
                               :checked="rolesmenu_ea_bienestar.includes('admin-ub')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'admin-ub'"
                               :checked="rolesmenu_ea_jefe.includes('admin-ub')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'admin-ub'"
                               :checked="rolesmenu_ea_vicerrector.includes('admin-ub')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'admin-ub'"
                               :checked="rolesmenu_directorio.includes('admin-ub')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'admin-ub'"
                               :checked="rolesmenu_administrador.includes('admin-ub')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td>Plan de Estudios</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'admin-ub'"
                               :checked="rolesmenu_cajas.includes('admin-ub')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'admin-ub'"
                               :checked="rolesmenu_registros.includes('admin-ub')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'admin-ub'"
                               :checked="rolesmenu_bienestar.includes('admin-ub')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'admin-ub'"
                               :checked="rolesmenu_jefe.includes('admin-ub')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'admin-ub'"
                               :checked="rolesmenu_sistemas.includes('admin-ub')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'admin-ub'"
                               :checked="rolesmenu_daf.includes('admin-ub')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'admin-ub'"
                               :checked="rolesmenu_postgrado.includes('admin-ub')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'admin-ub'"
                               :checked="rolesmenu_ea_cajas.includes('admin-ub')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'admin-ub'"
                               :checked="rolesmenu_ea_bienestar.includes('admin-ub')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'admin-ub'"
                               :checked="rolesmenu_ea_jefe.includes('admin-ub')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'admin-ub'"
                               :checked="rolesmenu_ea_vicerrector.includes('admin-ub')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'admin-ub'"
                               :checked="rolesmenu_directorio.includes('admin-ub')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'admin-ub'"
                               :checked="rolesmenu_administrador.includes('admin-ub')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td>Carreras</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'admin-ub'"
                               :checked="rolesmenu_cajas.includes('admin-ub')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'admin-ub'"
                               :checked="rolesmenu_registros.includes('admin-ub')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'admin-ub'"
                               :checked="rolesmenu_bienestar.includes('admin-ub')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'admin-ub'"
                               :checked="rolesmenu_jefe.includes('admin-ub')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'admin-ub'"
                               :checked="rolesmenu_sistemas.includes('admin-ub')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'admin-ub'"
                               :checked="rolesmenu_daf.includes('admin-ub')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'admin-ub'"
                               :checked="rolesmenu_postgrado.includes('admin-ub')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'admin-ub'"
                               :checked="rolesmenu_ea_cajas.includes('admin-ub')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'admin-ub'"
                               :checked="rolesmenu_ea_bienestar.includes('admin-ub')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'admin-ub'"
                               :checked="rolesmenu_ea_jefe.includes('admin-ub')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'admin-ub'"
                               :checked="rolesmenu_ea_vicerrector.includes('admin-ub')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'admin-ub'"
                               :checked="rolesmenu_directorio.includes('admin-ub')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'admin-ub'"
                               :checked="rolesmenu_administrador.includes('admin-ub')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                    <tr>
                      <td>Sedes</td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_cajas" :value="'admin-ub'"
                               :checked="rolesmenu_cajas.includes('admin-ub')"
                               class="form-control rolesmenu_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_registros" :value="'admin-ub'"
                               :checked="rolesmenu_registros.includes('admin-ub')"
                               class="form-control rolesmenu_registros" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_bienestar" :value="'admin-ub'"
                               :checked="rolesmenu_bienestar.includes('admin-ub')"
                               class="form-control rolesmenu_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_jefe" :value="'admin-ub'"
                               :checked="rolesmenu_jefe.includes('admin-ub')"
                               class="form-control rolesmenu_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_sistemas" :value="'admin-ub'"
                               :checked="rolesmenu_sistemas.includes('admin-ub')"
                               class="form-control rolesmenu_sistemas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_daf" :value="'admin-ub'"
                               :checked="rolesmenu_daf.includes('admin-ub')"
                               class="form-control rolesmenu_daf" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-success text-bold">
                        <input v-model="rolesmenu_postgrado" :value="'admin-ub'"
                               :checked="rolesmenu_postgrado.includes('admin-ub')"
                               class="form-control rolesmenu_postgrado" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_cajas" :value="'admin-ub'"
                               :checked="rolesmenu_ea_cajas.includes('admin-ub')"
                               class="form-control rolesmenu_ea_cajas" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_bienestar" :value="'admin-ub'"
                               :checked="rolesmenu_ea_bienestar.includes('admin-ub')"
                               class="form-control rolesmenu_ea_bienestar" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_jefe" :value="'admin-ub'"
                               :checked="rolesmenu_ea_jefe.includes('admin-ub')"
                               class="form-control rolesmenu_ea_jefe" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-primary text-bold">
                        <input v-model="rolesmenu_ea_vicerrector" :value="'admin-ub'"
                               :checked="rolesmenu_ea_vicerrector.includes('admin-ub')"
                               class="form-control rolesmenu_ea_vicerrector" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_directorio" :value="'admin-ub'"
                               :checked="rolesmenu_directorio.includes('admin-ub')"
                               class="form-control rolesmenu_directorio" name="rolesmenu[]" type="checkbox"/>
                      </td>
                      <td class="text-center align-middle text-warning text-bold">
                        <input v-model="rolesmenu_administrador" :value="'admin-ub'"
                               :checked="rolesmenu_administrador.includes('admin-ub')"
                               class="form-control rolesmenu_administrador" name="rolesmenu[]" type="checkbox"/>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 text-left">
                <button type="button" class="btn btn-primary" @click="update()">Grabar</button>
                <button type="button" class="btn btn-outline-danger" @click="goBack">Regresar a la pantalla anterior</button>
              </div>
              <div class="col-6 text-right">
                <button type="button" class="btn btn-warning" @click="updatePerfil">
                  Actualizar el perfil <strong>{{perfilSeleccionado}}</strong> con la selección actual
                </button>
              </div>
            </div>
          </CForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Toast from "@/views/notifications/Toast";
import Confirm from "@/views/notifications/Confirm";
import Alerts from "@/views/notifications/Alerts";
import Table from "@/views/base/Table.vue";

export default {
  name: 'EditUser',
  components: {
    Table,
    Toast,
    Alerts,
    Confirm,
  },
  props: {
    caption: {
      type: String,
      default: 'User id'
    },
  },
  data: () => {
    return {
      name: '',
      email: '',
      perfilUsuario:'',
      perfilSeleccionado:'',
      rolesmenu: [],

      rolesmenu_cajas: [],
      rolesmenu_registros: [],
      rolesmenu_bienestar: [],
      rolesmenu_jefe: [],
      rolesmenu_sistemas: [],
      rolesmenu_daf: [],
      rolesmenu_postgrado: [],

      rolesmenu_ea_cajas: [],
      rolesmenu_ea_bienestar: [],
      rolesmenu_ea_jefe: [],
      rolesmenu_ea_vicerrector: [],

      rolesmenu_directorio: [],
      rolesmenu_administrador: [],

      rolesmenu_base_cajas: [],
      rolesmenu_base_registros: ['inscripcion-nuevos','inscripcion-regulares'],
      rolesmenu_base_bienestar: [],
      rolesmenu_base_jefe: [],
      rolesmenu_base_sistemas: [],
      rolesmenu_base_daf: [],
      rolesmenu_base_postgrado: [],

      rolesmenu_base_ea_cajas: [],
      rolesmenu_base_ea_bienestar: [],
      rolesmenu_base_ea_jefe: [],
      rolesmenu_base_ea_vicerrector: [],

      rolesmenu_base_directorio: [],
      rolesmenu_base_administrador: [],

      roles: [],
      showMessage: false,
      message: '',
      dismissSecs: 7,
      dismissCountDown: 0,
      showDismissibleAlert: false
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
      // this.$router.replace({path: '/users'})
    },
    update() {
      let self = this;
      switch(self.perfilUsuario) {
            case 'rolesmenu_cajas':
              self.rolesmenu = self.rolesmenu_cajas;
              break;
            case 'rolesmenu_registros':
              self.rolesmenu = self.rolesmenu_registros;
              break;
            case 'rolesmenu_bienestar':
              self.rolesmenu = self.rolesmenu_bienestar;
              break;
            case 'rolesmenu_jefe':
              self.rolesmenu = self.rolesmenu_jefe;
              break;
            case 'rolesmenu_sistemas':
              self.rolesmenu = self.rolesmenu_sistemas;
              break;
            case 'rolesmenu_daf':
              self.rolesmenu = self.rolesmenu_daf;
              break;
            case 'rolesmenu_postgrado':
              self.rolesmenu = self.rolesmenu_postgrado;
              break;
            case 'rolesmenu_ea_cajas':
              self.rolesmenu = self.rolesmenu_ea_cajas;
              break;
            case 'rolesmenu_ea_bienestar':
              self.rolesmenu = self.rolesmenu_ea_bienestar;
              break;
            case 'rolesmenu_ea_jefe':
              self.rolesmenu = self.rolesmenu_ea_jefe;
              break;
            case 'rolesmenu_registros':
              self.rolesmenu = self.rolesmenu_ea_vicerrector;
              break;
            case 'rolesmenu_directorio':
              self.rolesmenu = self.rolesmenu_directorio;
              break;
            case 'rolesmenu_administrador':
              self.rolesmenu = self.rolesmenu_administrador;
              break;
          }
      axios.post(this.$apiAdress + '/api/users/' + self.$route.params.id + '?token=' + localStorage.getItem("api_token"),
          {
            _method: 'PUT',
            name: self.name,
            email: self.email,
            perfil: self.perfilUsuario,
            rolesmenu: self.rolesmenu,
          })
          .then(function (response) {
            self.message = 'Successfully updated user.';
            self.showAlert();
          })
          .catch(function (error) {
            self.$refs.mensajeToast.mostrarError(error, self);
          });
    },
    updatePerfil() {
      let self = this;
      let mensaje = 'Al actualizar el perfil:';
      mensaje += '<ul>';
      mensaje += '<li>No se aplicará automáticamente en todos los usuarios</li>';
      mensaje += '<li>Debe editar manualmente cada usuario para poder aplicar los cambios</li>';
      mensaje += '</ul>';
      mensaje += '<div class="text-center text-danger">¿Aplicar los cambios?</div>';
      this.$refs.confirm.confirm(mensaje, '', function () {
        self.$refs.alert.show('Actualizando el perfil');
        switch (self.perfilUsuario){
          case 'rolesmenu_cajas':self.rolesmenu=self.rolesmenu_cajas
            break;
          case 'rolesmenu_registros':self.rolesmenu=self.rolesmenu_registros
            break;
          case 'rolesmenu_bienestar':self.rolesmenu=self.rolesmenu_bienestar
            break;
          case 'rolesmenu_jefe':self.rolesmenu=self.rolesmenu_jefe
            break;
          case 'rolesmenu_sistemas':self.rolesmenu=self.rolesmenu_sistemas
            break;
          case 'rolesmenu_daf':self.rolesmenu=self.rolesmenu_daf
            break;
          case 'rolesmenu_postgrado':self.rolesmenu=self.rolesmenu_postgrado
            break;
          case 'rolesmenu_ea_cajas':self.rolesmenu=self.rolesmenu_ea_cajas
            break;
          case 'rolesmenu_ea_bienestar':self.rolesmenu=self.rolesmenu_ea_bienestar
            break;
          case 'rolesmenu_ea_jefe':self.rolesmenu=self.rolesmenu_ea_jefe
            break;
          case 'rolesmenu_ea_vicerrector':self.rolesmenu=self.rolesmenu_ea_vicerrector
            break;
          case 'rolesmenu_directorio':self.rolesmenu=self.rolesmenu_directorio
            break;
          case 'rolesmenu_administrador':self.rolesmenu=self.rolesmenu_administrador
            break;
        }
        axios.post(self.$apiAdress + '/api/users/updateperfil?token=' + localStorage.getItem("api_token"),
            {
              _method: 'POST',
              perfil_usuario: self.perfilUsuario,
              perfil_seleccionado: self.perfilSeleccionado,
              rolesmenu: self.rolesmenu,
            })
            .then(function (response) {
              self.$refs.alert.hide()
            })
            .catch(function (error) {
              self.$refs.mensajeToast.mostrarError(error, self);
            });
      })

    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    habilitaColumna(clase,descripcion){
      this.perfilUsuario=clase
      this.perfilSeleccionado=descripcion
      let columnas = [
          'rolesmenu_cajas',
          'rolesmenu_registros',
          'rolesmenu_bienestar',
          'rolesmenu_jefe',
          'rolesmenu_sistemas',
          'rolesmenu_daf',
          'rolesmenu_postgrado',
          'rolesmenu_ea_cajas',
          'rolesmenu_ea_bienestar',
          'rolesmenu_ea_jefe',
          'rolesmenu_ea_vicerrector',
          'rolesmenu_directorio',
          'rolesmenu_administrador',
      ]
      let boxes1=[]
      for(const col of columnas) {
        boxes1 = document.getElementsByClassName(col);
        for (const box of boxes1) {
          box.disabled = true;
        }
      }
      const boxes = document.getElementsByClassName(clase);
      for (const box of boxes) {
        box.disabled=false;
      }
      let base= clase.split('_');
      let nombreBase=''
      if(base[1]=='ea'){
        nombreBase=base[0]+'_base_'+base[1]+'_'+base[2];
      }else{
        nombreBase=base[0]+'_base_'+base[1];
      }
      switch (nombreBase){
        case 'rolesmenu_base_cajas':this.rolesmenu_cajas=this.rolesmenu_base_cajas
          break;
        case 'rolesmenu_base_registros':this.rolesmenu_registros=this.rolesmenu_base_registros
          break;
        case 'rolesmenu_base_bienestar':this.rolesmenu_bienestar=this.rolesmenu_base_bienestar
          break;
        case 'rolesmenu_base_jefe':this.rolesmenu_jefe=this.rolesmenu_base_jefe
          break;
        case 'rolesmenu_base_sistemas':this.rolesmenu_sistemas=this.rolesmenu_base_sistemas
          break;
        case 'rolesmenu_base_daf':this.rolesmenu_daf=this.rolesmenu_base_daf
          break;
        case 'rolesmenu_base_postgrado':this.rolesmenu_postgrado=this.rolesmenu_base_postgrado
          break;
        case 'rolesmenu_base_ea_cajas':this.rolesmenu_ea_cajas=this.rolesmenu_base_ea_cajas
          break;
        case 'rolesmenu_base_ea_bienestar':this.rolesmenu_ea_bienestar=this.rolesmenu_base_ea_bienestar
          break;
        case 'rolesmenu_base_ea_jefe':this.rolesmenu_ea_jefe=this.rolesmenu_base_ea_jefe
          break;
        case 'rolesmenu_base_ea_vicerrector':this.rolesmenu_ea_vicerrector=this.rolesmenu_base_ea_vicerrector
          break;
        case 'rolesmenu_base_directorio':this.rolesmenu_directorio=this.rolesmenu_base_directorio
          break;
        case 'rolesmenu_base_administrador':this.rolesmenu_administrador=this.rolesmenu_base_administrador
          break;
      }
    }
  },
  mounted: function () {
    let self = this;
    axios.get(this.$apiAdress + '/api/users/' + self.$route.params.id + '/edit?token=' + localStorage.getItem("api_token"))
        .then(function (response) {
          self.name = response.data.user.name;
          self.email = response.data.user.email;
          self.perfilUsuario = response.data.user.perfil
          self.rolesmenu = response.data.user.roles.split(',');

          if (response.data.perfiles.hasOwnProperty("rolesmenu_cajas")){ self.rolesmenu_base_cajas=response.data.perfiles.rolesmenu_cajas
            self.rolesmenu_cajas=response.data.perfiles.rolesmenu_cajas
          }
          if (response.data.perfiles.hasOwnProperty("rolesmenu_registros")){ self.rolesmenu_base_registros=response.data.perfiles.rolesmenu_registros
            self.rolesmenu_registros=response.data.perfiles.rolesmenu_registros
          }
          if (response.data.perfiles.hasOwnProperty("rolesmenu_bienestar")){ self.rolesmenu_base_bienestar=response.data.perfiles.rolesmenu_bienestar
            self.rolesmenu_bienestar=response.data.perfiles.rolesmenu_bienestar
          }
          if (response.data.perfiles.hasOwnProperty("rolesmenu_jefe")){ self.rolesmenu_base_jefe=response.data.perfiles.rolesmenu_jefe
            self.rolesmenu_jefe=response.data.perfiles.rolesmenu_jefe
          }
          if (response.data.perfiles.hasOwnProperty("rolesmenu_sistemas")){ self.rolesmenu_base_sistemas=response.data.perfiles.rolesmenu_sistemas
            self.rolesmenu_sistemas=response.data.perfiles.rolesmenu_sistemas
          }
          if (response.data.perfiles.hasOwnProperty("rolesmenu_daf")){ self.rolesmenu_base_daf=response.data.perfiles.rolesmenu_daf
            self.rolesmenu_daf=response.data.perfiles.rolesmenu_daf
          }
          if (response.data.perfiles.hasOwnProperty("rolesmenu_postgrado")){ self.rolesmenu_base_postgrado=response.data.perfiles.rolesmenu_postgrado
            self.rolesmenu_postgrado=response.data.perfiles.rolesmenu_postgrado
          }
          if (response.data.perfiles.hasOwnProperty("rolesmenu_ea_cajas")){ self.rolesmenu_base_ea_cajas=response.data.perfiles.rolesmenu_ea_cajas
            self.rolesmenu_ea_cajas=response.data.perfiles.rolesmenu_ea_cajas
          }
          if (response.data.perfiles.hasOwnProperty("rolesmenu_ea_bienestar")){ self.rolesmenu_base_ea_bienestar=response.data.perfiles.rolesmenu_ea_bienestar
            self.rolesmenu_ea_bienestar=response.data.perfiles.rolesmenu_ea_bienestar
          }
          if (response.data.perfiles.hasOwnProperty("rolesmenu_ea_jefe")){ self.rolesmenu_base_ea_jefe=response.data.perfiles.rolesmenu_ea_jefe
            self.rolesmenu_ea_jefe=response.data.perfiles.rolesmenu_ea_jefe
          }
          if (response.data.perfiles.hasOwnProperty("rolesmenu_ea_vicerrector")){ self.rolesmenu_base_ea_vicerrector=response.data.perfiles.rolesmenu_ea_vicerrector
            self.rolesmenu_ea_vicerrector=response.data.perfiles.rolesmenu_ea_vicerrector
          }
          if (response.data.perfiles.hasOwnProperty("rolesmenu_directorio")){ self.rolesmenu_base_directorio=response.data.perfiles.rolesmenu_directorio
            self.rolesmenu_directorio=response.data.perfiles.rolesmenu_directorio
          }
          if (response.data.perfiles.hasOwnProperty("rolesmenu_administrador")){ self.rolesmenu_base_administrador=response.data.perfiles.rolesmenu_administrador
            self.rolesmenu_administrador=response.data.perfiles.rolesmenu_administrador
          }

          self.habilitaColumna(self.perfilUsuario,self.perfilSeleccionado)
        })
        .catch(function (error) {
          self.$refs.mensajeToast.mostrarError(error, self);
        });
    // axios.get(this.$apiAdress + '/api/roles?token=' + localStorage.getItem("api_token"))
    //     .then(function (response) {
    //       self.roles = response.data;
    //     })
    //     .catch(function (error) {
    //       self.$refs.mensajeToast.mostrarError(error, self);
    //     });
  }
}


</script>
<style>
th{
  font-size: 10.5px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  min-width: 50px;
}
td{
  font-size: 12px;
}
</style>
